<template>
    <div>
        <nav>
            <div>
                <a href="#" @click.prevent="goBack"><i class="fal fa-arrow-left"></i> Назад</a>
            </div>
            <h4>Импорт счета</h4>
            <div></div>
        </nav>
        <p class="message">
            <span>Вы можете связать с приложением свой банк. Операции по счетам из этого банка будут подтягиваться автоматически.</span>
            <i class="far fa-info-circle"></i>
        </p>
        <div class="banks">
            <form method="POST" action="https://money.yandex.ru/oauth/authorize" accept-charset="utf-8" :class="{ 'active' : currentUser.yandex }">
                <input type="hidden" name="client_id" :value="yandexClientId">
                <input type="hidden" name="response_type" value="code">
                <input type="hidden" name="redirect_uri" :value="redirectURI">
                <input type="hidden" name="scope" value="account-info operation-history">
                <input type="hidden" name="instance_name" :value="currentUser.email">
                <button title="ЮMoney" :disabled="(currentUser.yandex) ? true : false">
                    <img src="/img/banks/yandex-money.png" alt="yandex-money">
                    <i @click="unlinkBank('yandex')" v-if="currentUser.yandex" class="far fa-times"></i>
                </button>
            </form>
            <form class="pointer" :class="{ 'active' : currentUser.monobank }" @submit="(e) => e.preventDefault()">
                <button title="Monobank!" @click.prevent="monobankAuthClicked()">
                    <img src="/img/banks/mono.png" alt="privatbank">
                    <i @click.stop="unlinkBank('monobank')" v-if="currentUser.monobank" class="far fa-times"></i>
                </button>
            </form>
            <form method="POST" action="" accept-charset="utf-8" class="comming">
                <button title="Появится в скором времени!" :disabled="true">
                    <img src="/img/banks/sberbank.png" alt="privatbank">
                    <i v-if="currentUser.pb" class="far fa-times"></i>
                </button>
            </form>
            <form method="POST" action="" accept-charset="utf-8" class="comming">
                <button title="Появится в скором времени!" :disabled="true">
                    <img src="/img/banks/privat24.png" alt="privatbank">
                    <i v-if="currentUser.pb" class="far fa-times"></i>
                </button>
            </form>
        </div>
        <transition name="fade">
            <div>
                <p v-if="loading">
                    <half-circle-spinner :animation-duration="1000" :size="16" :color="'#3E2BCE'" /> Выполняется авторизация...
                </p>
                <template v-else>
                    <p v-if="!isValid" class="message error">
                        <span>{{ errorMsg || 'Вы заполнили не все обязательные поля!' }}</span>
                        <i @click="isValid = true" class="far fa-times"></i>
                    </p>
                    <p v-if="currentUser.monobank && typeof currentUser.monobank === 'string'" class="message error">
                        <span>Откройте ссылку
                            <a class="importLink" :href="currentUser.monobank" target="_blank">{{ currentUser.monobank }}</a> в телефоне, на котором установлено приложение
                            <a class="importLink" href="https://www.monobank.ua/" target="_blank">Monobank</a>, и разрешите доступ. После этого нажмите
                            <a class="importLink" href="#" @click.prevent="checkMonobankAuthClicked()">Готово!</a>
                        </span>
                    </p>
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
import $ from 'jquery';
import { mapActions, mapGetters } from 'vuex';
import { HalfCircleSpinner } from 'epic-spinners';
import EventBus from '@/event-bus';
import { get } from 'lodash';

export default {
    name: 'category-import',
    components: {
        HalfCircleSpinner
    },
    data() {
        return {
            isValid: true,
            errorMsg: '',
            loading: false,
            yandexClientId: '9DD1477DDB32A94E84DB2D8C3FC03E011D3084622BA117533623D7E465A7DECC',
        }
    },
    computed: {
        ...mapGetters(["currentUser", "getAccounts"]),
        redirectURI () {
            // if url has account id = add id to redirectURI to linked YM to exist acc - else create new
            return (this.$route.query.id) ? 'https://splitmyfunds.com/import/account?id=' + this.$route.query.id : 'https://splitmyfunds.com/import/account'
        }
    },
    async mounted() {
        if (this.$route.query.error) {
            this.isValid = false;
            this.errorMsg = this.$route.query.error;
            $('html, body').animate({ scrollTop: $(document).height() }, 300);
        }
        if (this.$route.query.code) {
            try {
                if (this.currentUser.yandex) throw 'У вас уже есть счет с привязанной картой "ЮMoney"';
                this.loading = true;
                let code = this.$route.query.code, categoryId = this.$route.query.id;
                await this.yandexAuth({ code, categoryId });
            } catch (error) {
                this.isValid = false;
                this.errorMsg = error;
            }
            this.loading = false;
        }
        this.$nextTick(() => {
            window.document.querySelector('body').onfocus = async () => {
                // check monobank auth
                if (this.currentUser.monobank && typeof this.currentUser.monobank === 'string') {
                    this.checkMonobankAuthClicked()
                }
            };
        });
    },
    methods: {
        ...mapActions(["yandexAuth", "unlinkYandex", "monobankAuth", "checkMonobankAuth", "unlinkMonobank", "editCategory"]),
        unlinkBank(bank) {
            let bankName, unlinkMethod;

            switch (bank) {
                case 'yandex':
                    bankName = 'ЮMoney';
                    unlinkMethod = this.unlinkYandex;
                    break;
                case 'monobank':
                    bankName = 'Monobank';
                    unlinkMethod = this.unlinkMonobank;
                    break;
            }

            this.$showConfirmDialog({
                title: `Отвязать ${ bankName } от аккаунта?`,
                description: 'Операции перестанут подтягиваться автоматически.',
                approvedCallback: async () => {
                    try {
                        await unlinkMethod();
                    } catch (error) {
                        this.isValid = false;
                        this.errorMsg = error;
                    }
                }
            });
        },
        async monobankAuthClicked() {
            this.loading = true;
            $('html, body').animate({ scrollTop: $(document).height() }, 300);
            let monobank = this.currentUser.monobank;

            try {
                if (monobank) {
                    await this.checkMonobankAuthClicked();
                } else {
                    await this.monobankAuth();
                    // TODO: check operation system instead of screen width
                    if (monobank && typeof monobank === 'string' && window.screen.width < 769) {
                        window.open(monobank, '_blank');
                    }
                }
            } catch (error) {
                this.isValid = false;
                this.errorMsg = error;
            }

            $('html, body').animate({ scrollTop: $(document).height() }, 300);
            this.loading = false;
        },
        async checkMonobankAuthClicked() {
            this.loading = true;
            this.isValid = true;
            this.errorMsg = '';
            try {
                // if this.$route.query has id - checkMonobankAuth should return mono-accs to select, wich link to current category,
                // else - it adds all mono-accs
                let newAccounts = await this.checkMonobankAuth(this.$route.query.id);

                if (get(newAccounts, 'length')) {
                    let category = this.getAccounts.filter((account) => account.id === Number(this.$route.query.id))[0];

                    if (newAccounts.length === 1) {
                        await this.linkToAccount(category, newAccounts, 0);
                    } else {
                        this.$showConfirmDialog({
                            title: `К какому счету привзять "${ category.name }"?`,
                            description: newAccounts,
                            approvedCallback: async (acc) => {
                                await this.linkToAccount(category, newAccounts, acc);
                            }
                        });
                    }
                } else {
                    EventBus.$emit('UPDATE_BANKS_OPERATIONS');
                }
            } catch (error) {
                this.isValid = false;
                this.errorMsg = error;
            }
            this.loading = false;
        },
        async linkToAccount(category, newAccounts, index) {
            try {
                await this.editCategory({
                    categoryName: 'account',
                    categoryId: category.id,
                    name: category.name,
                    currency: newAccounts[index].currency,
                    balance: newAccounts[index].balance,
                    target: category.target,
                    accType: category.accType,
                    accOwners: category.accOwners,
                    isHidden: category.isHidden,
                    import: 'monobank',
                    cardNumber: newAccounts[index].cardNumber
                });
                EventBus.$emit('UPDATE_BANKS_OPERATIONS');
                this.$router.push('/category/settings/account/' + category.id);
            } catch (error) {
                this.isValid = false;
                this.errorMsg = error;
            }
        },
        goBack() {
            if (this.$route.query.id) {
                this.$router.push('/category/settings/account/' + this.$route.query.id)
            } else {
                this.$router.push('/add/account')
            }
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/forms.scss';

    .half-circle-spinner {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: -2px;
        margin-left: 15px;
    }


    nav {
        border-bottom: $border;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        a {
            display: inline-flex;
            align-items: center;
            color: $text;
            text-transform: uppercase;
            font-size: 20px;
            padding: 13px 0px;
            &:hover {
                text-decoration: none;
            }
            i {
                margin-right: 15px;
            }
        }
    }


    h4 {
        display: inline-block;
        font-size: 20px;
        color: $text;
        text-transform: uppercase;
        padding: 11px 0px;
    }

    .banks {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        form {
            position: relative;
            flex: 1 0 20%;
            max-width: 20%;
            min-width: 200px;
            display: flex;
            align-items: center;
            height: 120px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.37);
            padding: 10px 15px;
            margin: 10px 15px 20px 15px;
            &.active {
                border: 2px solid $second;
                &.pointer button {
                    cursor: pointer;
                }
                button {
                    cursor: default;
                }
            }
            &.comming {
                opacity: 0.5;
                button {
                    cursor: default;
                }
            }
        }
        button {
            width: 100%;
            padding: 0;
            border: none;
            cursor: pointer;
            outline: none;
            background: transparent;
            img {
                max-width: 100%;
            }
            i {
                position: absolute;
                font-size: 20px;
                top: 0;
                right: 0;
                padding: 7px 8px;
                color: $text-regular;
                background: #fff;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }

    .loading-wrap {
        position: relative;
        .loading {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }

    @media screen and (max-width: 1024px) {
        nav a {
            font-size: 16px;
            padding: 11px 0px;
            i {
                margin-right: 12px;
            }
        }
        h4 {
            font-size: 18px;
            padding: 10px 0;
        }
    }

    @media screen and (max-width: 610px) {
        nav {
            padding: 0 15px;
        }
        .banks {
            justify-content: space-around;
        }
    }
</style>
